import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import { fromLonLat, get } from 'ol/proj';
import { XYZ } from 'ol/source';



olMap = new Map({
  target: 'map',
  controls: defaultControls({ zoom: true, rotate: false }).extend([new ScaleLine({  units: "metric" })]),
  layers: [
    new TileLayer({
      source: new XYZ({
        url: '/tiles/{z}/{x}/{y}.png'
      }),
      visible : true
    }),
    new TileLayer({
      source: new XYZ({
        url: '/tiles_black/{z}/{x}/{y}.png'
      }),
      visible : false
    }),
    new TileLayer({
      source: new XYZ({
        url: '/tiles_white/{z}/{x}/{y}.png'
      }),
      visible : false
    }),
    new TileLayer({
      source: new XYZ({
        url: '/aero/{z}/{x}/{y}.jpeg'
      }),
      visible : false
    })
  ],
  view: new View({
    projection: get('EPSG:3857'),
    center: fromLonLat([127, 37.4], get('EPSG:3857')),
    zoom: 10,
    minZoom: 1,
    maxZoom: 19
  })
});
